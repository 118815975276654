import { getOrder } from '@/api/order/order'

const adx = {
  state: {
    pageType: '',
    categoryId: null,
    currentCityCode: null,
    orderId: 0,
    orderBean: {},
    beginUpdateMap: new Date(),
    mapType: '',
    stations: [],
    searchCondition: {},
    beginUpdatePurchase: new Date(),
    defaultProduct: null,
    bottomProduct: null,
    showAssets: [],
    checkSchedules: [],
    purchaseDuration: 0,
    leftReload: new Date(),
    ChinaMapData: {},
    updateChinaMap: new Date()
  },
  mutations: {
    set_adx_pageType (state, data) {
      state.pageType = data
    },
    set_adx_categoryId (state, data) {
      state.categoryId = data
    },
    set_adx_currentCityCode (state, data) {
      state.currentCityCode = data
    },
    set_adx_orderBean (state, data) {
      state.orderBean = data
    },
    set_adx_ID (state, data) {
      state.orderId = data
    },
    set_adx_update_map (state, data) {
      state.beginUpdateMap = data
    },
    set_adx_map_type (state, data) {
      state.mapType = data
    },
    set_adx_stations (state, data) {
      state.stations = data
    },
    set_adx_search_condition (state, data) {
      state.searchCondition = data
    },
    set_adx_update_purchase (state, data) {
      state.beginUpdatePurchase = data
    },
    set_adx_defaultProduct (state, data) {
      state.defaultProduct = data
    },
    set_adx_bottom_product (state, data) {
      state.bottomProduct = data
    },
    set_adx_show_assets (state, data) {
      state.showAssets = data
    },
    set_adx_check_schedule (state, data) {
      state.checkSchedules = data
    },
    set_adx_purchase_duration (state, data) {
      state.purchaseDuration = data
    },
    set_adx_update_left (state, data) {
      state.leftReload = data
    },
    set_adx_chinaMap_data (state, data) {
      state.ChinaMapData = data
    },
    set_adx_update_China_map (state, data) {
      state.updateChinaMap = data
    }
  },
  getters: {

  },
  actions: {
    // 获取订单基础信息
    getOrderBaseInfo ({ state, commit }, query) {
      return new Promise((resolve) => {
        getOrder(query).then(res => {
          commit('set_adx_orderBean', res)
          resolve(res)
        })
      })
    }
  }
}

export default adx
